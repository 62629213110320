<template>
  <div>
    <a-dropdown v-if="order" :placement="'bottomLeft'">
      <a-button>
        <a-icon type="car"></a-icon>
        Balíkobot
        <a-icon type="down"/>
      </a-button>
      <a-menu
        v-if="order.balikobot_parcels && (!Array.isArray(order.balikobot_parcels.items) || order.balikobot_parcels.items.length === 0)"
        slot="overlay">
        <a-menu-item :disabled="loading" :class="{'disabled': loading}" @click="addParcels()" key="balikobot-1">
          Přidat balík
        </a-menu-item>
        <a-menu-item :disabled="loading" :class="{'disabled': loading}" @click="addParcelDetail()" key="balikobot-2">
          Přidat balík s upřesněním
        </a-menu-item>
      </a-menu>
      <a-menu v-else slot="overlay">
        <a-menu-item :disabled="loading" :class="{'disabled': loading}" @click="dropParcel()" key="balikobot-1">
          Odebrat balík
        </a-menu-item>
        <a-menu-item :disabled="loading" :class="{'disabled': loading}" @click="orderParcel()" key="balikobot-2">
          Odeslat informace pro svoz
        </a-menu-item>
        <a-menu-item :disabled="loading" :class="{'disabled': loading}"
                     @click="showStickerPositions(order.balikobot_parcels.items[0], 0)" key="balikobot-3">
          Tisk štítku
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-modal v-model="visible" v-if="order" @ok="addParcelsDetail">
      <template slot="title">Expedice balíků: <span class="text-primary">{{ order.order_info.dhe_name }}</span>
      </template>
      <a-form :form="form" layout="vertical">
        <a-form-item
          label="Počet balíků"
          :validate-status="error('order_number') ? 'error' : ''"
          :help="error('order_number') || ''"
        >
          <a-input-number placeholder="Počet balíků"
                          :step="1" :min="1"
                          v-decorator="['order_number', {initialValue: 1, rules: [{min: 1, message: 'Počet balíků nemůže být méně než 1!'}]}]"/>
        </a-form-item>
        <a-form-item
          v-for="(item, index) in attributes"
          :key="'attribute' + item.name"
          :label="item.label"
          :validate-status="error('attribute['+index+']') ? 'error' : ''"
          :help="error('attribute['+index+']') || ''"
        >
          <a-input v-if="item.data_type === 'string'" :placeholder="item.label"
                   v-decorator="['attribute['+index+']']"/>
          <a-checkbox v-else-if="item.data_type === 'boolean'" :placeholder="item.label"
                      v-decorator="['attribute['+index+']', {valuePropName: 'checked'}]"/>
          <a-input-number v-else-if="item.data_type === 'float'" :placeholder="item.label"
                          :step="item.step" :min="item.min"
                          v-decorator="['attribute['+index+']', { rules: [{max: item.max, message: item.label + ' nemůže být delší než '+item.max+' znaků!'}]}]"/>
          <small class="d-block font-italic lh-15 mt-2">{{ item.description }}</small>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model="printVisible" v-if="order" title="Pozice štítku" @ok="printSticker(order.balikobot_parcels.items[0], 0)">
      <div style="margin:0 auto; text-align: center">
        <div>
          <a-button @click="selectPrintPosition(item)" :class="{'active': printPosition === item}" v-for="item in [1,2]" type="default" size="large" style="margin: 0.25em;padding: 0 2em; height: 4em" :key="'pos'+item">{{ item }}</a-button>
        </div>
        <div>
          <a-button @click="selectPrintPosition(item)" :class="{'active': printPosition === item}" v-for="item in [3,4]" type="default" size="large" style="margin: 0.25em;padding: 0 2em; height: 4em" :key="'pos'+item">{{ item }}</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import printJS from 'print-js'
import balikobot from '@/services/balikobot'

export default {
  name: 'my-balikobot-parcel',
  data() {
    return {
      loading: false,
      visible: false,
      printVisible: false,
      printPosition: 1,
    }
  },
  props: {
    error: Function,
  },
  mixins: [balikobot],
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  computed: {
    order: function () {
      return this.$store.getters['order/getDetail']
    },
    attributes: function () {
      return this.$store.getters['orderBalikobot/attributes']
    },
  },
  methods: {
    selectPrintPosition(item) {
      this.printPosition = item
    },
    addParcels() {
      this.loading = true
      this.$store.dispatch('orderBalikobot/addParcels', { orderId: this.order.order_info.odr_id, data: null })
        .then(() => {

        })
        .catch((response) => {
          this.renderErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    addParcelDetail() {
      this.getAttributes().then(() => {
        this.visible = true
      })
    },
    addParcelsDetail() {
      this.loading = true
      const data = {
        order_number: this.form.getFieldValue('order_number'),
      }
      this.form.getFieldValue('attribute').forEach((value, key) => {
        if (value) {
          data[this.attributes[key].name] = value
        }
      })
      this.$store.dispatch('orderBalikobot/addParcels', { orderId: this.order.order_info.odr_id, data: data })
        .then(() => {
          this.visible = false
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    getAttributes() {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('orderBalikobot/getAttributes', this.order.order_info.odr_id)
          .then(() => {
            resolve()
          })
          .catch((response) => {
            this.renderErrors(response)
            reject(response)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    dropParcel() {
      this.loading = true
      this.$store.dispatch('orderBalikobot/dropParcels', this.order.order_info.odr_id)
        .then(() => {

        })
        .catch((response) => {
          this.renderErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    orderParcel() {
      this.loading = true
      this.$store.dispatch('orderBalikobot/orderShipment', this.order.order_info.odr_id)
        .then(() => {

        })
        .catch((response) => {
          this.renderErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    showStickerPositions() {
      this.printVisible = true
    },
    printSticker(parcel, index) {
      printJS({
        printable: parcel.label_url + '?p=' + this.printPosition,
        type: 'pdf',
        onPrintDialogClose: () => {
          if (this.order.balikobot_parcels.items[index + 1]) {
            this.printSticker(this.order.balikobot_parcels.items[index + 1], index + 1)
          } else {
            this.$store.dispatch('orderBalikobot/printLabels', this.order.order_info.odr_id)
            this.printVisible = false
          }
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
