<template>
  <section>
    <div class="card">
      <div class="card-header">
        <div class="utils__title">
          <strong>Objednávky - Detail <span class="utils__link--underlined text-primary">#{{ this.id }}</span></strong>
        </div>
        <action-tools :router-options="routerOptions" :handle-refresh="initDetail"></action-tools>
      </div>
      <div class="card-body">
        <div class="table-operations">
          <a-tabs @change="key => onTabChange(key, 'tabActiveKey')" :activeKey="tabActiveKey">
            <a-tab-pane v-for="item in tabList" :key="item.key">
              <a-badge slot="tab">
                <a-icon :type="item.icon" />
                {{ item.tab }}
              </a-badge>
            </a-tab-pane>
          </a-tabs>
          <a-row type="flex" justify="start" :gutter="20">
            <a-col>
              <my-balikobot-parcel :error="error"></my-balikobot-parcel>
            </a-col>
            <a-col v-if="detail && detail.payment_services && Array.isArray(detail.payment_services.payments)">
              <my-comgate-button :order-id="id" :payments="detail.payment_services.payments"></my-comgate-button>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>

    <div v-if="detail !== null">
      <div v-if="tabActiveKey === 'info'">
        <a-row type="flex" justify="start" :gutter="30">
          <a-col :xl="6" :md="8" :sm="12" :xs="24">
            <div class="card card--withShadow card--more_on-hover">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Detail objednávky</strong>
                </div>
                <div class="card--more">
                  <a-button type="circle" icon="edit" @click="editable.info = true"></a-button>
                </div>
              </div>
              <div class="card-body">
                <div v-if="!editable.info">
                  <abbr title="ID" class="mr-2">ID:</abbr>{{ this.id }}<br>
                  <abbr title="Jazyk zákazníka" class="mr-2">Jazyk:</abbr>{{ detail.order_info.lge_name }}<br>
                  <abbr title="Kurz v době nákupu"
                        class="mr-2">Kurz:</abbr>{{ detail.order_info.currency_rate | currency }} /
                  {{ detail.order_info.currency_amount + " " + detail.order_info.currency_symbol }}<br>
                  <abbr title="Měna zákazníka" class="mr-2">Měna:</abbr>{{ detail.order_info.currency_name }}
                  [{{ detail.order_info.currency_symbol }}]<br>
                  <abbr title="Objednáno" class="mr-2">Objednáno:</abbr>{{ toLocaleDate(detail.order_info.created_at) }}<br>
                  <abbr title="Odstraněno" class="mr-2">Odstraněno:</abbr>
                  <a-tag :color="detail.order_info.odr_is_deleted ? 'red' : 'green'">
                    {{ detail.order_info.odr_is_deleted ? 'ano' : 'ne' }}
                  </a-tag>
                  <br>
                  <abbr title="Status" class="mr-2">Status:</abbr>
                  <a-tag :color="statuses.find(x => x.status === detail.order_info.status).color">
                    {{ statuses.find(x => x.status === detail.order_info.status).text }}
                  </a-tag>
                  <span v-if="detail.order_info.is_canceled_by_user"> - stornováno zákazníkem</span>
                  <br>
                  <abbr title="Uživatel" class="mr-2">Uživatel:</abbr>
                  <router-link class="utils__link--blue utils__link--underlined"
                               :to="'/user/registered/detail/' + detail.order_info.uer_id"
                               v-if="detail.order_info.uer_type === 'RID'">#{{ detail.order_info.uer_id }}
                  </router-link>
                  <router-link class="utils__link--blue utils__link--underlined"
                               :to="'/user/admin/detail/' + detail.order_info.uer_id"
                               v-if="detail.order_info.uer_type === 'AMR'">#{{ detail.order_info.uer_id }}
                  </router-link>
                  <span v-else>#{{ detail.order_info.uer_id }}</span>
                </div>
                <a-form :form="forms.info" layout="horizontal" @submit="updateInfo($event, forms.info, 'info')" v-else>
                  <a-form-item
                    label="Status"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('status') ? 'error' : ''"
                    :help="error('status') || ''"
                  >
                    <a-select
                      v-decorator="['status', { initialValue: detail.order_info.status, rules: [{required: true, message: 'Status musí být vyplněn!'}]}]">
                      <a-select-option v-for="(status, index) in statuses" :value="status.status" :key="'status'+index">
                        {{ status.text }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <div class="form-actions">
                    <a-button class="mr-2" @click="editable.info = false" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary"
                              :disabled="loading || hasErrors(forms.info.getFieldsError())">Upravit
                    </a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
          <a-col :xl="6" :md="8" :sm="12" :xs="24">
            <div class="card card--withShadow card--more_on-hover">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Fakturační adresa</strong>
                </div>
                <div class="card--more">
                  <a-button type="circle" icon="edit" @click="editable.big = true"></a-button>
                </div>
              </div>
              <div class="card-body">
                <address v-if="!editable.big">
                  {{ detail.order_info.billing_address.name }}<br>
                  {{ detail.order_info.billing_address.street }}<br>
                  {{ detail.order_info.billing_address.city }} {{ getZipFormatted(detail.order_info.billing_address.zip) }}<br>
                  <div v-if="countries.length === 0">
                    {{ detail.order_info.billing_address.country.name }}<br>
                  </div>
                  <div v-else>
                    {{ countryName(detail.order_info.billing_address.country_id) }}
                  </div>
                </address>
                <a-form :form="forms.big" layout="horizontal"
                        @submit="updateBillingAddress($event, detail.order_info.billing_address.id, forms.big, 'big')" v-else>
                  <a-form-item
                    label="Jméno"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('name') ? 'error' : ''"
                    :help="error('name') || ''"
                  >
                    <a-input placeholder="Jméno"
                             v-decorator="['name', { initialValue: detail.order_info.billing_address.name, rules: [{max: 100, message: 'Křestní jméno a příjmení (název firmy) nemůže být delší než 100 znaků!'}, {required: true, message: 'Křestní jméno a příjmení (název firmy) musí být vyplněno!'}]}]"/>
                  </a-form-item>
                  <a-form-item
                    label="Ulice"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('street') ? 'error' : ''"
                    :help="error('street') || ''"
                  >
                    <a-input placeholder="Ulice"
                             v-decorator="['street', { initialValue: detail.order_info.billing_address.street, rules: [{max: 60, message: 'Ulice nemůže být delší než 60 znaků!'}, {required: true, message: 'Ulice musí být vyplněno!'}]}]"/>
                  </a-form-item>
                  <a-form-item
                    label="Město"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('city') ? 'error' : ''"
                    :help="error('city') || ''"
                  >
                    <a-input placeholder="Město"
                             v-decorator="['city', { initialValue: detail.order_info.billing_address.city, rules: [{max: 60, message: 'Město nemůže být delší než 60 znaků!'}, {required: true, message: 'Město musí být vyplněno!'}]}]"/>
                  </a-form-item>
                  <a-form-item
                    label="PSČ"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('zip') ? 'error' : ''"
                    :help="error('zip') || ''"
                  >
                    <a-input placeholder="PSČ"
                             v-decorator="['zip', { initialValue: detail.order_info.billing_address.zip, rules: [{max: 10, message: 'PSČ nemůže být delší než 10 znaků!'}, {required: true, message: 'PSČ musí být vyplněno!'}]}]"/>
                  </a-form-item>
                  <a-form-item
                    label="Země"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('country_id') ? 'error' : ''"
                    :help="error('country_id') || ''"
                  >
                    <a-select
                      v-decorator="['country_id', { initialValue: detail.order_info.billing_address.country_id, rules: [{required: true, message: 'Země musí být vyplněna!'}]}]">
                      <a-select-option v-for="country in countries" :value="country.id" :key="'big_country_id'+country.id"
                                       :loading="loading">{{ country.current_language.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <div class="form-actions">
                    <a-button class="mr-2" @click="editable.big = false" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary"
                              :disabled="loading || hasErrors(forms.big.getFieldsError())">Upravit
                    </a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
          <a-col :xl="6" :md="8" :sm="12" :xs="24">
            <div class="card card--withShadow card--more_on-hover">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Dodací adresa</strong>
                </div>
                <div class="card--more">
                  <a-button type="circle" icon="edit" @click="editable.dly = true"></a-button>
                </div>
              </div>
              <div class="card-body">
                <address v-if="!editable.dly">
                  {{ detail.order_info.delivery_address.name }}<br>
                  {{ detail.order_info.delivery_address.street }}<br>
                  {{ detail.order_info.delivery_address.city }} {{ getZipFormatted(detail.order_info.delivery_address.zip) }}<br>
                  <div v-if="countries.length === 0">
                    {{ detail.order_info.delivery_address.country.name }}<br>
                  </div>
                  <div v-else>
                    {{ countryName(detail.order_info.delivery_address.country_id) }}
                  </div>
                </address>
                <a-form :form="forms.dly" layout="horizontal"
                        @submit="updateDeliveryAddress($event, detail.order_info.delivery_address.id, forms.dly, 'dly')" v-else>
                  <a-form-item
                    label="Jméno"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('name') ? 'error' : ''"
                    :help="error('name') || ''"
                  >
                    <a-input placeholder="Křestní jméno a příjmení (název firmy)"
                             v-decorator="['name', { initialValue: detail.order_info.delivery_address.name, rules: [{max: 100, message: 'Křestní jméno a příjmení (název firmy) nemůže být delší než 100 znaků!'}, {required: true, message: 'Křestní jméno a příjmení (název firmy) musí být vyplněno!'}]}]"/>
                  </a-form-item>
                  <a-form-item
                    label="Ulice"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('street') ? 'error' : ''"
                    :help="error('street') || ''"
                  >
                    <a-input placeholder="Ulice"
                             v-decorator="['street', { initialValue: detail.order_info.delivery_address.street, rules: [{max: 60, message: 'Ulice nemůže být delší než 60 znaků!'}, {required: true, message: 'Ulice musí být vyplněno!'}]}]"/>
                  </a-form-item>
                  <a-form-item
                    label="Město"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('city') ? 'error' : ''"
                    :help="error('city') || ''"
                  >
                    <a-input placeholder="Město"
                             v-decorator="['city', { initialValue: detail.order_info.delivery_address.city, rules: [{max: 60, message: 'Město nemůže být delší než 60 znaků!'}, {required: true, message: 'Město musí být vyplněno!'}]}]"/>
                  </a-form-item>
                  <a-form-item
                    label="PSČ"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('zip') ? 'error' : ''"
                    :help="error('zip') || ''"
                  >
                    <a-input placeholder="PSČ"
                             v-decorator="['zip', { initialValue: detail.order_info.delivery_address.zip, rules: [{max: 10, message: 'PSČ nemůže být delší než 10 znaků!'}, {required: true, message: 'PSČ musí být vyplněno!'}]}]"/>
                  </a-form-item>
                  <a-form-item
                    label="Země"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('country_id') ? 'error' : ''"
                    :help="error('country_id') || ''"
                  >
                    <a-select
                      v-decorator="['country_id', { initialValue: detail.order_info.delivery_address.country_id, rules: [{required: true, message: 'Země musí být vyplněna!'}]}]">
                      <a-select-option v-for="country in countries" :value="country.id" :key="'dly_country_id'+country.id"
                                       :loading="loading">{{ country.current_language.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <div class="form-actions">
                    <a-button class="mr-2" @click="editable.dly = false" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary"
                              :disabled="loading || hasErrors(forms.dly.getFieldsError())">Upravit
                    </a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
          <a-col :xl="6" :md="8" :sm="12" :xs="24">
            <div class="card card--withShadow card--more_on-hover">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Kontakt</strong>
                </div>
                <div class="card--more">
                  <a-button type="circle" icon="edit" @click="editable.contact = true"></a-button>
                </div>
              </div>
              <div class="card-body">
                <div v-if="!editable.contact">
                  <div v-if="detail.order_info.contact.email">
                    <abbr title="E-mail" class="mr-2">E-mail:</abbr>
                    <span>
                      {{ detail.order_info.contact.email }}
                    </span>
                    <a-icon class="ml-2" @click="toClipboard(detail.order_info.contact.email)" type="copy"></a-icon>
                    <br>
                  </div>
                  <abbr title="Telefon" class="mr-2">Telefon:</abbr>
                  <span>
                    {{ getTelephoneNumberFormatted('('+getPhoneCode(detail.order_info.contact.phone_code_id)+')'+detail.order_info.contact.telephone_number) }}
                  </span>
                  <a-icon class="ml-2" @click="toClipboard(getTelephoneNumberFormatted('('+getPhoneCode(detail.order_info.contact.phone_code_id)+')'+detail.order_info.contact.telephone_number))" type="copy"></a-icon>
                  <br>
                </div>
                <a-form :form="forms.contact" layout="horizontal"
                        @submit="updateContact($event, detail.order_info.contact_id, forms.contact, 'contact')" v-else>
                  <a-form-item
                    label="E-mail"
                    :validate-status="error('email') ? 'error' : ''"
                    :help="error('email') || ''"
                  >
                    <a-input placeholder="E-mail"
                             v-decorator="['email', { initialValue: detail.order_info.contact.email, rules: [{required: true, message: 'E-mail musí být vyplněn!'},{max: 100, message: 'E-mail nemůže být delší než 100 znaků!'}]}]"/>
                  </a-form-item>
                  <a-form-item
                    label="Telefon"
                    :validate-status="error('telephone_number') ? 'error' : ''"
                    :help="error('telephone_number') || ''"
                  >
                    <a-row>
                      <a-col :span="8" class="pr-2">
                        <a-select
                          v-decorator="['phone_code_id', { initialValue: detail.order_info.contact.phone_code_id, rules: [{required: true, message: 'Předvolba musí být vyplněna!'}]}]">
                          <a-select-option v-for="country in countries" :value="country.id" :key="'phone_code_id'+country.id"
                                           :loading="loading">+{{ country.phone_code }}
                          </a-select-option>
                        </a-select>
                      </a-col>
                      <a-col :span="16">
                        <a-input placeholder="Telefon"
                                 v-decorator="['telephone_number', { initialValue: detail.order_info.contact.telephone_number, rules: [{required: true, message: 'Telefon musí být vyplněn!'}, {max: 20, message: 'Telefon nemůže být delší než 20 znaků!'}]}]"/>
                      </a-col>
                    </a-row>
                  </a-form-item>
                  <div class="form-actions">
                    <a-button class="mr-2" @click="editable.contact = false" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary"
                              :disabled="loading || hasErrors(forms.contact.getFieldsError())">Upravit
                    </a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row type="flex" justify="start" :gutter="30">
          <a-col :xl="6" :md="8" :sm="12" :xs="24">
            <div class="card card--withShadow card--more_on-hover">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Firemní údaje</strong>
                </div>
                <div class="card--more">
                  <a-button type="circle" icon="edit" @click="editable.company = true"></a-button>
                </div>
              </div>
              <div class="card-body">
                <div v-if="!editable.company">
                  <template v-if="detail.order_info.company_id !== null">
                    <abbr title="Firma" class="mr-2">Firma:</abbr>{{ detail.order_info.company.name }}<br>
                    <div v-if="detail.order_info.company.tin !== null">
                      <abbr title="IČ" class="mr-2">IČ:</abbr>{{ detail.order_info.company.tin }}
                    </div>
                    <div v-if="detail.order_info.company.vatin !== null">
                      <abbr title="DIČ" class="mr-2">DIČ:</abbr>{{ detail.order_info.company.vatin }}<br>
                    </div>
                  </template>
                  <a-empty v-else></a-empty>
                </div>
                <a-form :form="forms.company" layout="horizontal"
                        @submit="updateCompany($event, detail.order_info.company_id, forms.company, 'company')" v-else>
                  <a-form-item
                    label="Firma"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('name') ? 'error' : ''"
                    :help="error('name') || ''"
                  >
                    <a-input placeholder="Firma"
                             v-decorator="['name', { initialValue: detail.order_info.company.name, rules: [{max: 100, message: 'Firma nemůže být delší než 100 znaků!'}]}]"/>
                  </a-form-item>
                  <a-form-item
                    label="IČ"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('tin') ? 'error' : ''"
                    :help="error('tin') || ''"
                  >
                    <a-input placeholder="IČ"
                             v-decorator="['tin', { initialValue: detail.order_info.company.tin, rules: [{max: 20, message: 'IČ nemůže být delší než 20 znaků!'}]}]"/>
                  </a-form-item>
                  <a-form-item
                    label="DIČ"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :validate-status="error('vatin') ? 'error' : ''"
                    :help="error('vatin') || ''"
                  >
                    <a-input placeholder="DIČ"
                             v-decorator="['vatin', { initialValue: detail.order_info.company.vatin, rules: [{max: 20, message: 'DIČ nemůže být delší než 20 znaků!'}]}]"/>
                  </a-form-item>
                  <div class="form-actions">
                    <a-button class="mr-2" @click="editable.company = false" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary"
                              :disabled="loading || hasErrors(forms.company.getFieldsError())">Upravit
                    </a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
          <a-col :xl="6" :md="8" :sm="12" :xs="24">
            <div class="card card--withShadow card--more_on-hover">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Doprava</strong>
                </div>
                <div class="card--more">
                  <a-button type="circle" icon="edit" @click="editable.delivery = true"></a-button>
                </div>
              </div>
              <div class="card-body">
                <div v-if="!editable.delivery">
                  <span v-if="!item.delivery.changed">{{ detail.order_info.dhe_name }}</span>
                  <span v-else>{{ deliveryName(detail.order_info.dhy_id) }}</span>
                  : {{ detail.order_info.delivery_total | currency }}
                </div>
                <a-form :form="forms.delivery"
                        @submit="updateDeliveryPayment($event, detail.order_info.odr_id, forms.delivery, 'delivery')"
                        v-else>
                  <a-form-item
                    label="Doprava"
                    :validate-status="error('dhy_id') ? 'error' : ''"
                    :help="error('dhy_id') || ''"
                  >
                    <a-select
                      v-decorator="['dhy_id', { initialValue: detail.order_info.dhy_id, rules: [{required: true, message: 'Doprava musí být vyplněna!'}]}]">
                      <a-select-option v-if="!item.delivery.found" :value="detail.order_info.dhy_id" :loading="loading">
                        {{ detail.order_info.dhe_name }}
                      </a-select-option>
                      <a-select-option v-for="delivery in deliveries.filter(x => x.dly_id !== detail.order_info.dly_id)"
                                       :value="delivery.current_history.dhy_id"
                                       :key="'dhy_id'+delivery.current_history.dhy_id" :loading="loading">
                        {{ delivery.current_history.languages[0].pivot.dhe_name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item
                    :label="'Cena [' + this.$currency + ']'"
                    :validate-status="error('delivery_total') ? 'error' : ''"
                    :help="error('delivery_total') || ''"
                  >
                    <a-input-number :min="0" :placeholder="'Cena [' + this.$currency + ']'"
                                    v-decorator="['delivery_total', { initialValue: detail.order_info.delivery_total, rules: [{required: true, message: 'Cena musí být vyplněna!'}]}]"/>
                  </a-form-item>

                  <div class="form-actions">
                    <a-button class="mr-2" @click="editable.delivery = null" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">
                      Upravit
                    </a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
          <a-col :xl="6" :md="8" :sm="12" :xs="24">
            <div class="card card--withShadow card--more_on-hover">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Platba</strong>
                </div>
                <div class="card--more">
                  <a-button type="circle" icon="edit" @click="editable.payment = true"></a-button>
                </div>
              </div>
              <div class="card-body">
                <div v-if="!editable.payment">
                  <span v-if="!item.payment.changed">{{ detail.order_info.phe_name }}</span>
                  <span v-else>{{ paymentName(detail.order_info.pty_id) }}</span>
                  : {{ detail.order_info.payment_total | currency }}
                </div>
                <a-form :form="forms.payment"
                        @submit="updateDeliveryPayment($event, detail.order_info.odr_id, forms.payment, 'payment')"
                        v-else>
                  <a-form-item
                    label="Platba"
                    :validate-status="error('pty_id') ? 'error' : ''"
                    :help="error('pty_id') || ''"
                  >
                    <a-select
                      v-decorator="['pty_id', { initialValue: detail.order_info.pty_id, rules: [{required: true, message: 'Platba musí být vyplněna!'}]}]">
                      <a-select-option v-if="!item.payment.found" :value="detail.order_info.pty_id" :loading="loading">
                        {{ detail.order_info.phe_name }}
                      </a-select-option>
                      <a-select-option v-for="payment in payments.filter(x => x.pmt_id !== detail.order_info.pmt_id)"
                                       :value="payment.current_history.pty_id"
                                       :key="'phy_id' + payment.current_history.pty_id" :loading="loading">
                        {{ payment.current_history.languages[0].phe_name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item
                    :label="'Cena [' + this.$currency + ']'"
                    :validate-status="error('payment_total') ? 'error' : ''"
                    :help="error('payment_total') || ''"
                  >
                    <a-input-number :min="0" :placeholder="'Cena [' + this.$currency + ']'"
                                    v-decorator="['payment_total', { initialValue: detail.order_info.payment_total, rules: [{required: true, message: 'Cena musí být vyplněna!'}]}]"/>
                  </a-form-item>

                  <div class="form-actions">
                    <a-button class="mr-2" @click="editable.payment = null" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">
                      Upravit
                    </a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
          <a-col :xl="6" :md="8" :sm="12" :xs="24">
            <div class="card card--withShadow card--more_on-hover">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Poznámka zákazníka</strong>
                </div>
              </div>
              <div class="card-body">
                <a-empty v-if="detail.order_info.note === null"></a-empty>
                <template v-else>
                  {{ detail.order_info.note }}
                </template>
              </div>
            </div>
          </a-col>
          <a-col :xl="6" :md="8" :sm="12" :xs="24">
            <div class="card card--withShadow card--more_on-hover">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Poznámka pro kurýra</strong>
                </div>
                <div class="card--more">
                  <a-button type="circle" icon="edit" @click="editable.courier_note = true"></a-button>
                </div>
              </div>
              <div class="card-body">
                <div v-if="!editable.courier_note">
                  <template v-if="detail.order_info.courier_note">
                    {{ detail.order_info.courier_note }}
                  </template>
                  <a-empty v-else></a-empty>
                </div>
                <a-form :form="forms.courier_note" @submit="onSubmitUpdateCourierNote($event, forms.courier_note, 'courier_note')" v-else>
                  <a-form-item
                    label="Poznámka pro kurýra"
                    :validate-status="error('courier_note') ? 'error' : ''"
                    :help="error('courier_note') || ''"
                  >
                    <a-textarea placeholder="Poznámka pro kurýra"
                               v-decorator="['courier_note', { initialValue: detail.order_info.courier_note, rules: [{max: 50, message: 'Poznámka pro kurýra nemůže být delší než 50 znaků!'}]}]"/>
                  </a-form-item>

                  <div class="form-actions">
                    <a-button class="mr-2" @click="editable.courier_note = null" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">
                      Upravit
                    </a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-if="tabActiveKey === 'payment'">
        <a-row type="flex" justify="start" :gutter="30">
          <a-col :xl="6" :md="8" :sm="12" :xs="24">
            <div class="card card--withShadow card--more_on-hover">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Informace</strong>
                </div>
                <div class="card--more">
                  <a-button type="circle" icon="edit" @click="editable.payment_status = true"></a-button>
                </div>
              </div>
              <div class="card-body">
                <div v-if="!editable.payment_status">
                  <abbr title="Status platby" class="mr-2">Status platby:</abbr>
                  <a-tag :color="paymentStatuses.find(x => x.status === detail.order_info.payment_status).color">
                    {{ paymentStatuses.find(x => x.status === detail.order_info.payment_status).text }}
                  </a-tag>
                  <template v-if="detail.payment_services !== null && detail.payment_services.payments">
                    <br>
                    <abbr title="Platební služba" class="mr-2">Platební služba:</abbr> ComGate
                  </template>
                </div>
                <a-form :form="forms.payment_status" layout="horizontal"
                        @submit="updatePaymentStatus($event, forms.payment_status)" v-else>
                  <a-form-item
                    label="Status platby"
                    :validate-status="error('payment_status') ? 'error' : ''"
                    :help="error('payment_status') || ''"
                  >
                    <a-select
                      v-decorator="['payment_status', { initialValue: detail.order_info.payment_status, rules: [{required: true, message: 'Status platby musí být vyplněn!'}]}]">
                      <a-select-option :loading="loading" v-for="paymentStatus in paymentStatuses" :value="paymentStatus.status" :key="'paymentStatus'+paymentStatus.status">{{ paymentStatus.text }}</a-select-option>
                    </a-select>
                  </a-form-item>

                  <div class="form-actions">
                    <a-button class="mr-2" @click="editable.payment_status = false" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary"
                              :disabled="loading || hasErrors(forms.payment_status.getFieldsError())">Upravit
                    </a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
        </a-row>
        <div v-if="detail.payment_services !== null && detail.payment_services.payments" class="card">
          <div class="card-header">
            <div class="utils__title">
              <strong>Platby přes platební služby</strong>
            </div>
          </div>
          <div class="card-body">
            <a-table
              tableLayout="auto"
              :rowKey="record => record.id"
              :columns="PaymentServicePaymentsColumns"
              :loading="loading"
              :dataSource="detail.payment_services.payments"
              class="utils__scrollTable"
              :scroll="{ x: '100%' }"
            >
              <span slot="id" slot-scope="text">
                #{{ text }}
              </span>
              <span slot="dates" slot-scope="text">
                {{ toLocaleDate(text) }}
              </span>
              <span slot="status" slot-scope="text">
                <a-tag :color="paymentStatuses.find(x => x.status === text).color">
                  {{ paymentStatuses.find(x => x.status === text).text }}
                </a-tag>
              </span>
                <a-table
                  slot="statuses"
                  slot-scope="record"
                  :rowKey="record => record.id + 'd'"
                  :columns="PaymentServicePaymentsInnerColumns"
                  :data-source="record"
                  :pagination="false"
                >
              <span slot="id" slot-scope="text">
                #{{ text }}
              </span>
                  <span slot="dates" slot-scope="text">
                {{ toLocaleDate(text) }}
              </span>
                  <span slot="status" slot-scope="text">
                <a-tag :color="paymentStatuses.find(x => x.status === text).color">
                  {{ paymentStatuses.find(x => x.status === text).text }}
                </a-tag>
              </span>
              </a-table>
            </a-table>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <div class="utils__title">
              <strong>Statusy platby</strong>
            </div>
          </div>
          <div class="card-body">
            <a-table
              tableLayout="auto"
              :rowKey="record => record.id"
              :columns="PaymentStatusesColumns"
              :loading="loading"
              :dataSource="orderPaymentStatuses"
              class="utils__scrollTable"
              :scroll="{ x: '100%' }"
            >
              <span slot="id" slot-scope="text">
                #{{ text }}
              </span>
              <template slot="createdBy"
                        slot-scope="record"
              >
                <router-link
                  v-if="record.created_by !== null"
                  class="utils__link--underlined"
                  :to="'/user/admin/detail/' + record.created_by"
                >#{{ record.created_by }}, {{ record.email }}
                </router-link>
                <span v-else>
                  {{ record.created_by_instance }}
                </span>
              </template>
              <span slot="dates" slot-scope="text">
                {{ toLocaleDate(text) }}
              </span>
              <span
                slot="money"
                slot-scope="text"
              >
                {{ text | currency }}
              </span>
              <span slot="status" slot-scope="text">
                <a-tag :color="paymentStatuses.find(x => x.status === text).color">
                  {{ paymentStatuses.find(x => x.status === text).text }}
                </a-tag>
              </span>
            </a-table>
          </div>
        </div>
      </div>
      <div v-if="tabActiveKey === 'delivery'">
        <a-row type="flex" justify="start" :gutter="30">
          <a-col :xl="24" :md="24" :sm="24" :xs="24">
            <div class="card">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Informace</strong>
                </div>
              </div>
              <div class="card-body">
                <template v-if="detail.delivery_service.data !== null">
                  <abbr title="Dopravní služba" class="mr-2">Dopravní služba:</abbr>{{ detail.delivery_service.type.description }}
                  <template v-for="item in deliveryServiceAttributes">
                    <template v-if="detail.delivery_service.data[item.key] !== null">
                     <br :key="'br'+item.key"><abbr :key="'abbr'+item.key" :title="item.name" class="mr-2">{{item.name}}:</abbr><span :key="'span'+item.key" v-html="renderDeliveryServiceAttribute(detail.delivery_service.data[item.key], item.type)"></span>
                    </template>
                  </template>
                  <div v-html="renderDeliveryServiceAddress(detail.delivery_service)"></div>
                </template>
                <a-empty v-else></a-empty>
              </div>
            </div>
          </a-col>
          <a-col :xl="24" :md="24" :sm="24" :xs="24">
            <div class="card">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Balíkobot</strong>
                </div>
              </div>
              <div class="card-body">
                <template v-if="detail.balikobot_parcels">
                  <a-tabs @change="key => onTabChange(key, 'balikobotTabActiveKey')" :activeKey="balikobotTabActiveKey">
                    <a-tab-pane v-for="item in balikobotTabList" :key="item.key">
                      <a-badge slot="tab">
                        <a-icon :type="item.icon" />
                        {{ item.tab }}
                      </a-badge>
                    </a-tab-pane>
                  </a-tabs>
                  <template v-if="balikobotTabActiveKey === 'info'">
                    <div class="mb-2" v-if="detail.order_info.last_track_status">
                      <abbr title="Poslední aktualizace stavu" class="mr-2">Poslední aktualizace stavu:</abbr>{{ $options.filters.formatDate(detail.order_info.last_track_status_at) }}<br>
                      <abbr title="Stav v balíkobotu" class="mr-2">Stav v balíkobotu:</abbr><a-badge :color="orderTrackStatuses.find(x => x.status === detail.order_info.last_track_status).color" :text="orderTrackStatuses.find(x => x.status === detail.order_info.last_track_status).text"></a-badge>
                    </div>
                    <a-list bordered :data-source="detail.balikobot_parcels.items">
                      <a-list-item slot="renderItem" slot-scope="parcel">
                        <abbr title="Vytvořeno" class="mr-2">Vytvořeno:</abbr>{{ $options.filters.formatDate(parcel.created_at) }}<br>
                        <abbr title="Poslední úprava" class="mr-2">Poslední úprava:</abbr>{{ $options.filters.formatDate(parcel.updated_at) }}<br>
                        <abbr title="ID balíku" class="mr-2">ID balíku:</abbr>{{ parcel.package_id }}<br>
                        <abbr title="Pořadí balíku" class="mr-2">Pořadí balíku:</abbr>{{ parcel.order_number }}<br>
                        <abbr title="Sledování zásilky" class="mr-2">Sledování zásilky:</abbr><a class="utils__link--blue" :href="parcel.track_url">{{parcel.track_url}}</a><a-icon class="ml-2" @click="toClipboard(parcel.track_url)" type="copy"></a-icon><br>
                        <abbr title="Předávací arch" class="mr-2">Předávací arch:</abbr>
                        <template v-if="!parcel.handover_url">---</template>
                        <template v-else>
                          <a class="utils__link--blue" :href="parcel.handover_url">{{parcel.handover_url}}</a>
                          <a-icon class="ml-2" @click="toClipboard(parcel.handover_url)" type="copy"></a-icon>
                        </template>
                        <br>
                        <abbr title="Štítek" class="mr-2">Štítek:</abbr>
                        <template v-if="parcel.label_url">
                          <a class="utils__link--blue" :href="parcel.label_url">{{ parcel.label_url }}</a><a-icon class="ml-2" @click="toClipboard(parcel.label_url)" type="copy"></a-icon>
                        </template>
                        <template v-else>---</template>
                        <br>
                        <abbr title="Objednání svozu" class="mr-2">Objednání svozu:</abbr>
                        <template v-if="parcel.ordered_shipment_at">
                          {{ $options.filters.formatDate(parcel.ordered_shipment_at) }}
                        </template>
                        <template v-else>---</template>
                        <br>
                        <abbr title="Tisk štítku" class="mr-2">Tisk štítku:</abbr>
                        <template v-if="parcel.printed_labels_at">
                          {{ $options.filters.formatDate(parcel.printed_labels_at) }}
                        </template>
                        <template v-else>---</template>
                      </a-list-item>
                    </a-list>
                  </template>
                  <template v-else-if="balikobotTabActiveKey === 'history'">
                    <a-empty v-if="detail.balikobot_parcels.histories == 0"></a-empty>
                    <a-timeline v-else mode="alternate">
                      <a-timeline-item :class="$style.timelineItem" v-for="item in detail.balikobot_parcels.histories" :key="'time' + item.id">
                        {{ $options.filters.formatDate(item.created_at) }}
                        <template v-if="item.user === null">
                          <a-avatar :class="$style.timelineAvatar" slot="dot" shape="square" size="large" icon="robot"></a-avatar>
                          <div class="utils__title" style="text-transform: none;">Systém</div>
                        </template>
                        <template v-else>
                          <a-avatar :class="$style.timelineAvatar" slot="dot" shape="square" size="large" :src="item.user.avatar ? $avatarVectorPath + item.user.avatar : null" :icon="item.user.avatar ? null : 'user'"></a-avatar>
                          <div class="utils__title" style="text-transform: none;">{{ item.user.uer_first_name + ' ' + item.user.uer_last_name }}</div>
                        </template>
                        <a-tag :color="balikobotParcelStatuses.find(x => x.key === item.status).color">{{ balikobotParcelStatuses.find(x => x.key === item.status).text }}</a-tag>
                      </a-timeline-item>
                    </a-timeline>
                  </template>
                </template>
                <template v-else>
                  <a-empty></a-empty>
                </template>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-if="tabActiveKey === 'product'">
        <div class="card">
          <div class="card-header">
            <div class="utils__title">
              <strong>Produkty</strong>
            </div>
            <div class="action-tools">
              <div class="links">
                <a-popconfirm placement="bottomLeft" @confirm="confirmAddItem" okText="Potvrdit" cancelText="Zrušit">
                  <a-icon slot="icon" type="info-circle" class="text-primary"/>
                  <template slot="title">
                    <p>Vyberte si produkt, který chcete přidat</p>
                    <a-tree-select
                      style="display:block;"
                      :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                      :treeData="treeData"
                      placeholder="-- Vyber kategorii --"
                      @change="this.changeCategory"
                      :value="selectedCategory"
                      :loading="item.categories.loading"
                    >
                    </a-tree-select>
                    <a-select
                      class="mt-2"
                      showSearch
                      placeholder="-- Vyberte si produkt --"
                      optionFilterProp="children"
                      @change="handleSelectProductChange"
                      :value="item.product.active"
                      style="width: 100%"
                      :loading="item.product.loading"
                      :filterOption="filterOption"
                      :options="products.map((x, index) => { return { value: index.toString(), label: x.history_languages.length > 0 ? '# ' + x.put_id + ' | ' + x.history_languages[0].pivot.phe_name : '# ' + x.put_id + ' | není nastaveno' } })"
                    >
                    </a-select>
                    <a-select
                      class="mt-2"
                      showSearch
                      placeholder="-- Vyberte si variantu produktu --"
                      optionFilterProp="children"
                      @change="handleSelectProductOptionChange"
                      :value="item.product.pon_id"
                      style="width: 100%"
                      :loading="item.product.loading"
                      :filterOption="filterOption"
                      :options="item.product.selected === null ? [] : item.product.selected.current_options.map(x => { return { value: x.pon_id.toString(), label: x.languages.length > 0 ? '# ' + x.pon_id + ' | ' + x.languages[0].pivot.poe_name : '# ' + x.pon_id + ' | není nastaveno' } })"
                    >
                    </a-select>
                    <p class="mt-2"><span class="utils__link--underlined">Cena s DPH:</span> {{
                        (item.product.selected === null ? 0 : (item.product.selected.current_history.phy_price_vat - ((item.product.selected.current_history.phy_price_vat * item.product.discount) / 100))) | currency
                      }}</p>
                    <a-input-number
                      :min="1"
                      :step="1"
                      v-model="item.product.oim_quantity"
                      placeholder="Množství"></a-input-number>&nbsp;ks
                    <a-input-number
                      class="mt-2 d-block" placeholder="Sleva %" :min="0" :max="100" :step="0.01" :precision="2"
                      v-model="item.product.discount" :defaultValue="0"/>
                    <p class="mt-2"><span class="utils__link--underlined">Celkem s DPH:</span>
                      {{
                        (item.product.selected === null ? 0 : (item.product.selected.current_history.phy_price_vat - ((item.product.selected.current_history.phy_price_vat * item.product.discount) / 100)) * item.product.oim_quantity) | currency
                      }}
                    </p>
                  </template>
                  <a-tooltip title="Přidat položku">
                    <a-icon
                      type="plus-circle"
                      :style="{ fontSize: '1.38rem' }"
                      theme="twoTone"
                      @click="clickAddItem"
                    >
                    </a-icon>
                  </a-tooltip>
                </a-popconfirm>
              </div>
            </div>
          </div>
          <div class="card-body">
            <a-table
              tableLayout="auto"
              :rowKey="record => record.put_id"
              :columns="columns"
              :loading="loading"
              :dataSource="detail.items"
              class="utils__scrollTable"
              :scroll="{ x: '100%' }"
            >
              <router-link
                slot="put_id"
                slot-scope="value"
                class="utils__link--underlined"
                :to="'/ecommerce/product/detail/' + value"
              >#{{ value }}
              </router-link>
              <span
                slot="price"
                slot-scope="text"
              >
                {{ text | currency }}
              </span>
              <template
                slot="price_changeable"
                slot-scope="record"
              >
                <editable-cell
                  :input-value="parseFloat(record.price_item_without_discount_with_vat)"
                  :text="($options.filters.currency(record.price_item_without_discount_with_vat))"
                  @change="onPriceChange(record, $event)"
                  :min="0"
                  :step="0.01"
                />
              </template>
              <span slot="product_name" slot-scope="record">
                {{ record.phe_name + (record.poe_name ? ' - ' + record.poe_name : '') }}
              </span>
              <template
                slot="discount_changeable"
                slot-scope="record"
              >
                <editable-cell
                  :input-value="parseFloat(record.discount)"
                  :text="(record.discount + ' %')"
                  @change="onDiscountChange(record, $event)"
                  :min="0"
                  :max="100"
                  :step="0.01"
                />
              </template>
              <template slot="quantity" slot-scope="record">
                <editable-cell
                  :input-value="parseInt(record.oim_quantity)"
                  :text="($options.filters.number(record.oim_quantity, '0,0') + ' ks')"
                  @change="onQuantityChange(record, $event)"
                />
              </template>
              <span slot="weight_total" slot-scope="record" v-if="record.per_piece">
                {{ record.weight_total | number('0,0') }} g
              </span>
              <span slot="action" slot-scope="record">
                <a-popconfirm @confirm="confirmDeleteItem(record.oim_id)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                      <p>Opravdu si přejete odstranit položku: #{{ record.put_id }} ?</p>
                  </template>
                  <a-button icon="delete" size="small">Odstranit</a-button>
                </a-popconfirm>
              </span>
            </a-table>
          </div>
        </div>
        <a-row type="flex" justify="end">
          <a-col :span="6" :xl="6" :md="8" :sm="12" :xs="24">
            <div class="card card--withShadow card--more_on-hover">
              <div class="card-body">
                <abbr title="Zboží" class="mr-2">Zboží:</abbr>{{
                  detail.items.reduce((partialSum, a) => partialSum + a.price_item_with_discount_with_vat * a.oim_quantity, 0) | currency
                }}<br>
                <abbr title="Doprava" class="mr-2">Doprava:</abbr>{{ detail.order_info.delivery_total | currency }}<br>
                <abbr title="Platba" class="mr-2">Platba:</abbr>{{ detail.order_info.payment_total | currency }}<br>
                <abbr title="Celkem s DPH" class="mr-2">Celkem s
                  DPH:</abbr>{{ detail.order_info.order_total_vat_rounded | currency }}<br>
                <abbr title="Celkem bez DPH" class="mr-2">Celkem bez
                  DPH:</abbr>{{ detail.order_info.order_total_no_vat | currency }}<br>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-if="tabActiveKey === 'note'">
        <a-row type="flex" justify="start" :gutter="30">
          <a-col :xl="8" :md="12" :xs="24" v-for="item in detail.notes" :key="'note'+item.one_id">
            <div class="card card--withShadow">
              <div class="card-header">
                <div class="utils__titleDescription">Posledně upraveno: {{ toLocaleDate(item.updated_at) }}</div>
                <div><small>Admin:
                  <router-link class="utils__link--underlined text-primary" :to="'/user/admin/detail/' + item.uer_id">
                    #{{ item.uer_id }}
                  </router-link>
                </small></div>

                <div class="card--more">
                  <a-dropdown :placement="'bottomRight'">
                    <a-button type="circle">
                      <a-icon type="ellipsis" :rotate="90"></a-icon>
                    </a-button>
                    <a-menu slot="overlay" @click="clickActionNote($event, item)">
                      <a-menu-item :key="0">
                        <a-icon type="edit" class="mr-2"></a-icon>
                        Upravit
                      </a-menu-item>
                      <a-menu-item :key="1">
                        <a-popconfirm @confirm="deleteNote(item)" okText="Potvrdit" cancelText="Zrušit">
                          <template slot="title">
                            <p>Opravdu si přejete odstranit položku: #{{ item.one_id }} ?</p>
                          </template>
                          <a-icon type="delete" class="mr-2"></a-icon>
                          Odstranit
                        </a-popconfirm>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </div>
              <div class="card-body">
                <div v-if="editable.note !== item.one_id">
                  <div class="utils__title">
                    <strong>{{ item.one_title }}</strong>
                  </div>
                  {{ item.one_content }}
                </div>
                <a-form :form="form" @submit="onSubmitUpdateNote($event, item)" v-else>
                  <a-form-item
                    label="Nadpis"
                    :validate-status="error('one_title') ? 'error' : ''"
                    :help="error('one_title') || ''"
                  >
                    <a-input placeholder="Nadpis"
                             v-decorator="['one_title', { initialValue: item.one_title, rules: [{max: 50, message: 'Nadpis nemůže být delší než 50 znaků!'}, {required: true, message: 'Nadpis musí být vyplněn!'}]}]"/>
                  </a-form-item>

                  <a-form-item
                    label="Obsah"
                    :validate-status="error('one_content') ? 'error' : ''"
                    :help="error('one_content') || ''"
                  >
                    <a-textarea :autoSize="{ minRows: 4, maxRows: 10 }" placeholder="Obsah"
                                v-decorator="['one_content', {initialValue: item.one_content, rules: [{required: true, message: 'Obsah musí být vyplněn!'}]}]"/>
                  </a-form-item>

                  <div class="form-actions">
                    <a-button class="mr-2" @click="editable.note = null" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">
                      Upravit
                    </a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
          <a-col :xl="6" :md="8" :sm="12" :xs="24">
            <a-button
              type="dashed"
              block
              size="large"
              @click="onClickAddNote"
              v-if="!isAddNote"
            >
              <a-icon type="plus"/>
              Přidat poznámku
            </a-button>
            <div class="card" v-else>
              <div class="card-body">
                <a-form :form="form" @submit="onSubmitAddNote">
                  <a-form-item
                    label="Nadpis"
                    :validate-status="error('one_title') ? 'error' : ''"
                    :help="error('one_title') || ''"
                  >
                    <a-input placeholder="Nadpis"
                             v-decorator="['one_title', {rules: [{max: 50, message: 'Nadpis nemůže být delší než 50 znaků!'}, {required: true, message: 'Nadpis musí být vyplněn!'}]}]"/>
                  </a-form-item>

                  <a-form-item
                    label="Obsah"
                    :validate-status="error('one_content') ? 'error' : ''"
                    :help="error('one_content') || ''"
                  >
                    <a-textarea :autoSize="{ minRows: 4, maxRows: 10 }" placeholder="Obsah"
                                v-decorator="['one_content', {rules: [{required: true, message: 'Obsah musí být vyplněn!'}]}]"/>
                  </a-form-item>

                  <div class="form-actions">
                    <a-button class="mr-2" @click="isAddNote = !isAddNote" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">
                      Přidat
                    </a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-if="tabActiveKey === 'history'">
        <div class="card">
          <div class="card-body">
            <a-timeline mode="alternate">
              <a-timeline-item :class="$style.timelineItem" :color="statuses.find(x => x.status === item.ohy_status).color"
                               v-for="item in detail.histories" :key="'time' + item.ohy_id">
                {{ toLocaleDate(item.ohy_date_from) }}
                <template v-if="item.user === null">
                  <a-avatar :class="$style.timelineAvatar" slot="dot" shape="square" size="large" icon="robot"></a-avatar>
                  <div class="utils__title" style="text-transform: none;">Systém</div>
                </template>
                <template v-else>
                  <a-avatar :class="$style.timelineAvatar" slot="dot" shape="square" size="large" :src="item.user.avatar ? $avatarVectorPath + item.user.avatar : null" :icon="item.user.avatar ? null : 'user'"></a-avatar>
                  <div class="utils__title" style="text-transform: none;" v-if="item.user.uer_first_name">{{ item.user.uer_first_name + ' ' + item.user.uer_last_name }}</div>
                  <div class="utils__title" style="text-transform: none;" v-else>{{detail.order_info.billing_address.name}}</div>
                </template>
                <a-tag :color="statuses.find(x => x.status === item.ohy_status).color">{{ statuses.find(x => x.status === item.ohy_status).text }}</a-tag>
                <div v-if="item.sent_notifier_type" class="mt-1">
                  <a-tag>
                    {{ notifierTypes.find(x => x.name === item.sent_notifier_type).value }}
                  </a-tag>
                  <a-tag v-if="item.sent_notifier" class="ml-1">
                    {{ notifiers.find(x => x.name === item.sent_notifier).value }}
                  </a-tag>
                  <a-tag v-if="item.sent_notifier_flag" class="ml-1">
                    {{ notifierFlags.find(x => x.name === item.sent_notifier_flag).value }}
                  </a-tag>
                </div>
              </a-timeline-item>
            </a-timeline>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import MyBalikobotParcel from '@/components/MyComponents/Balikobot/my-balikobot-parcel'
import MyComgateButton from '@/components/MyComponents/Comgate/my-comgate-button'
import EditableCell from '@/components/MyComponents/EditableCell'
import copy from '@/services/copy.js'
import { hasErrors } from '../../../../services/forms'
import { getZipFormatted } from '@/services/zipFormatter'
import { getTelephoneNumberFormatted } from '@/services/telephoneNumberFormatter'
import { isRefunded } from '@/services/orderPaymentStatus'

export default {
  components: {
    ActionTools, EditableCell, MyBalikobotParcel, MyComgateButton,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  mixins: [copy],
  data() {
    return {
      id: this.$route.params.id,
      editable: {
        payment_status: false,
        big: false,
        payment: false,
        delivery: false,
        info: false,
        dly: false,
        contact: false,
        company: false,
        note: null,
        courier_note: null,
      },
      PaymentServicePaymentsInnerColumns: [
        {
          title: 'ID',
          dataIndex: 'id',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.id - b.id,
          scopedSlots: {
            customRender: 'id',
          },
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'created_at',
          sorter: (a, b) => {
            return new Date(a.created_at) - new Date(b.created_at)
          },
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          sorter: (a, b) => {
            return a.status.localeCompare(b.status)
          },
          scopedSlots: {
            customRender: 'status',
          },
        },
      ],
      PaymentServicePaymentsColumns: [
        {
          title: 'ID',
          dataIndex: 'id',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.id - b.id,
          scopedSlots: {
            customRender: 'id',
          },
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'created_at',
          sorter: (a, b) => {
            return new Date(a.created_at) - new Date(b.created_at)
          },
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Metoda',
          dataIndex: 'method',
          sorter: (a, b) => {
            return a.method.localeCompare(b.method)
          },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          sorter: (a, b) => {
            return a.status.localeCompare(b.status)
          },
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: 'ID transakce',
          dataIndex: 'transaction_id',
          sorter: (a, b) => {
            return a.transaction_id.localeCompare(b.transaction_id)
          },
        },
        {
          title: 'Variabilní symbol',
          dataIndex: 'variable_symbol',
          sorter: (a, b) => {
            return a.variable_symbol.localeCompare(b.variable_symbol)
          },
        },
        {
          title: 'Historie statusů',
          dataIndex: 'statuses',
          scopedSlots: {
            customRender: 'statuses',
          },
        },
      ],
      PaymentStatusesColumns: [
        {
          title: 'ID',
          dataIndex: 'id',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.id - b.id,
          scopedSlots: {
            customRender: 'id',
          },
        },
        {
          title: 'ID FIO transakce',
          dataIndex: 'transaction_id',
          sorter: (a, b) => {
            return a.transaction_id.localeCompare(b.transaction_id)
          },
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'created_at',
          sorter: (a, b) => {
            return new Date(a.created_at) - new Date(b.created_at)
          },
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Vytvořeno uživatelem',
          dataIndex: '',
          scopedSlots: {
            customRender: 'createdBy',
          },
        },
        {
          title: 'Cena objednávky v čase FIO kontroly',
          dataIndex: 'order_total_vat_rounded',
          sorter: (a, b) => {
            return a.order_total_vat_rounded - b.order_total_vat_rounded
          },
          scopedSlots: {
            customRender: 'money',
          },
        },
        {
          title: 'Zaplaceno',
          dataIndex: 'paid_amount',
          sorter: (a, b) => {
            return a.paid_amount - b.paid_amount
          },
          scopedSlots: {
            customRender: 'money',
          },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          sorter: (a, b) => {
            return a.status.localeCompare(b.status)
          },
          scopedSlots: {
            customRender: 'status',
          },
        },
      ],
      treeData: [],
      item: {
        delivery: {
          found: false,
          changed: false,
        },
        payment: {
          found: false,
          changed: false,
        },
        categories: {
          loading: false,
        },
        product: {
          selected: null,
          loading: false,
          pon_id: null,
          oim_quantity: 1,
          discount: 0,
          active: null,
        },
      },
      forms: {
        big: this.$form.createForm(this),
        payment_status: this.$form.createForm(this),
        dly: this.$form.createForm(this),
        payment: this.$form.createForm(this),
        delivery: this.$form.createForm(this),
        info: this.$form.createForm(this),
        contact: this.$form.createForm(this),
        company: this.$form.createForm(this),
        courier_note: this.$form.createForm(this),
      },
      loading: false,
      formItemLayout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      hasErrors,
      tabActiveKey: 'info',
      balikobotTabActiveKey: 'info',
      isAddNote: false,
      searchText: '',
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/ecommerce/order',
          title: 'Seznam',
        },
      ],
      balikobotTabList: [
        {
          icon: 'info-circle',
          key: 'info',
          tab: 'Informace',
        },
        {
          icon: 'clock-circle',
          key: 'history',
          tab: 'Historie',
        },
      ],
      tabList: [
        {
          icon: 'info-circle',
          key: 'info',
          tab: 'Informace',
        },
        {
          icon: 'car',
          key: 'delivery',
          tab: 'Dopravní informace',
        },
        {
          icon: 'clock-circle',
          key: 'history',
          tab: 'Historie',
        },
        {
          icon: 'credit-card',
          key: 'payment',
          tab: 'Platební informace',
        },
        {
          icon: 'form',
          key: 'note',
          tab: 'Poznámky',
        },
        {
          icon: 'apple',
          key: 'product',
          tab: 'Produkty',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'put_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.put_id - b.put_id,
          scopedSlots: {
            customRender: 'put_id',
          },
        },
        {
          title: 'Produkt',
          dataIndex: '',
          sorter: (a, b) => {
            return a.phe_name.localeCompare(b.phe_name)
          },
          scopedSlots: {
            customRender: 'product_name',
          },
        },
        {
          title: 'Cena bez slevy s DPH',
          dataIndex: '',
          scopedSlots: {
            customRender: 'price_changeable',
          },
        },
        {
          title: 'Sleva',
          dataIndex: '',
          scopedSlots: {
            customRender: 'discount_changeable',
          },
        },
        {
          title: 'Cena bez DPH',
          dataIndex: 'price_item_with_discount_without_vat',
          sorter: (a, b) => {
            return a.price_item_with_discount_without_vat - b.price_item_with_discount_without_vat
          },
          scopedSlots: {
            customRender: 'price',
          },
        },
        {
          title: 'Cena s DPH',
          dataIndex: 'price_item_with_discount_with_vat',
          sorter: (a, b) => {
            return a.price_item_with_discount_with_vat - b.price_item_with_discount_with_vat
          },
          scopedSlots: {
            customRender: 'price',
          },
        },
        {
          title: 'Množství',
          dataIndex: '',
          scopedSlots: {
            customRender: 'quantity',
          },
        },
        {
          title: 'Celkem bez DPH',
          dataIndex: 'price_total_with_discount_without_vat',
          sorter: (a, b) => {
            return a.price_total_with_discount_without_vat - b.price_total_with_discount_without_vat
          },
          scopedSlots: {
            customRender: 'price',
          },
        },
        {
          title: 'Celkem s DPH',
          dataIndex: 'price_total_with_discount_with_vat',
          sorter: (a, b) => {
            return a.price_total_with_discount_with_vat - b.price_total_with_discount_with_vat
          },
          scopedSlots: {
            customRender: 'price',
          },
        },
        {
          title: '',
          dataIndex: '',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    orderPaymentStatuses: function () {
      if (this.detail && Array.isArray(this.detail.order_payment_statuses)) {
        this.detail.order_payment_statuses.forEach(x => {
          if (isRefunded(x.status) && x.paid_amount !== null) {
            x.paid_amount = x.paid_amount / 100
          }
        })
        return this.detail.order_payment_statuses
      } else {
        return []
      }
    },
    orderTrackStatuses: function () {
      return this.$store.getters['order/getTrackStatuses']
    },
    notifiers: function () {
      return this.$store.getters['order/getNotifiers']
    },
    notifierFlags: function () {
      return this.$store.getters['order/getNotifierFlags']
    },
    notifierTypes: function () {
      return this.$store.getters['order/getNotifierTypes']
    },
    deliveryServiceAttributes: function () {
      if (this.detail !== null) {
        return this.$store.getters[this.$store.getters['deliveryServices/serviceByName'](this.detail.delivery_service.type.name).store + '/getAttributes']
      }
      return []
    },
    detail: function () {
      return this.$store.getters['order/getDetail']
    },
    balikobotParcelStatuses: function () {
      return this.$store.getters['balikobot/statuses']
    },
    language: function () {
      return this.$store.getters['language/active']
    },
    countries: function () {
      return this.$store.getters['country/currentLanguage']('')
    },
    deliveries: function () {
      return this.$store.getters['delivery/currentLanguage']('').filter(x => x.current_history !== null)
    },
    payments: function () {
      return this.$store.getters['payment/currentLanguage']('').filter(x => x.current_history !== null)
    },
    statuses: function () {
      return this.$store.getters['order/getStatuses']
    },
    paymentStatuses: function () {
      return this.$store.getters['order/getPaymentStatuses']
    },
    products: function () {
      return this.$store.getters['product/currentLanguage']('')
    },
    selectedCategory: function () {
      return this.$store.getters['category/getActive']
    },
    categories: function () {
      return this.$store.getters['category/currentLanguage']
    },
    days: function () {
      return this.$store.getters['deliveryServices/getDays']
    },
  },
  methods: {
    isRefunded,
    getTelephoneNumberFormatted,
    getZipFormatted,
    initDetail() {
      this.loading = true
      this.$store.dispatch('order/getOne', this.id)
        .finally(() => {
          this.loading = false
        })
    },
    getPhoneCode(phoneCodeId) {
      const country = this.countries.find(x => x.id === phoneCodeId)
      if (country) {
        return country.phone_code
      }
      return phoneCodeId
    },
    onSubmitUpdateCourierNote(e, form, editable) {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('order/put', { orderId: this.id, item: values })
            .then(() => {
              this.editable[editable] = false
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    renderDeliveryServiceAddress(address) {
      if (this.detail !== null) {
        return this.$store.getters[this.$store.getters['deliveryServices/serviceByName'](this.detail.delivery_service.type.name).store + '/renderAddress'](address)
      }
    },
    getDateFrom(index, key) {
      const sub = index - key
      if (sub > 0) {
        return this.$moment(this.detail.order_info.created_at).subtract(sub, 'days').format('DD. MM.')
      } else {
        return this.$moment(this.detail.order_info.created_at).add(Math.abs(sub), 'days').format('DD. MM.')
      }
    },
    renderDeliveryServiceAttribute(value, type) {
      switch (type) {
      case 'date':
        return this.toLocaleDate(value)
      case 'hours': {
        const dayIndex = Number.parseInt(this.$moment(this.detail.order_info.created_at).format('d')) - 1
        const parsed = JSON.parse(value)
        let html = '<ul class="ml-4 list-unstyled" style="max-width:500px;">'
        parsed.forEach((val, key) => {
          html += '<li><span ' + (key === dayIndex ? 'class="font-weight-bold"' : '') + ' style="display:inline-block;width:30%">' + (this.days[key] + ', ' + this.getDateFrom(dayIndex, key)) + ':</span><span class="text-right ' + (key === dayIndex ? 'font-weight-bold' : '') + '">'
          if (val === null) {
            html += 'Zavřeno'
          } else {
            html += val.od_do.od + ' - ' + val.od_do.do
          }
          html += '</span></li>'
        })
        return html
      }
      case 'bool':
        return '<span class="' + (value === undefined ? '' : value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger') + '">' + (value ? 'ano' : 'ne') + '</span>'
      default:
        return value
      }
    },
    countryName(id) {
      let name = ''
      this.countries.some((value) => {
        if (value.id === id) {
          name = value.current_language.name
          return true
        }
      })
      return name
    },
    changeCategory(key) {
      this.$store.commit('category/SET_ACTIVE', key.toString())
      this.loadProducts()
    },
    handleSelectProductChange(e) {
      this.item.product.active = e
      this.item.product.selected = e === null ? null : this.products[parseInt(e)]
    },
    handleSelectProductOptionChange(e) {
      this.item.product.pon_id = e === null ? null : parseInt(e)
    },
    clickAddItem() {
      if (this.categories.length === 0) {
        this.item.categories.loading = true
        this.$store.dispatch('category/getList')
          .then(() => {
            if (this.categories.length > 0) {
              this.changeCategory(this.categories[0].cey_id)
            }
          })
          .catch(() => {
          })
          .finally(() => {
            this.item.categories.loading = false
          })
      }
    },
    loadProducts() {
      this.item.product.loading = true
      this.$store.dispatch('product/getList')
        .then(() => {
          this.handleSelectProductChange(null)
          this.handleSelectProductOptionChange(null)
        })
        .catch(() => {
        })
        .finally(() => {
          this.item.product.loading = false
        })
    },
    paymentName(id) {
      let name = ''
      this.payments.some((value) => {
        if (value.current_history.pty_id === id) {
          name = value.current_history.languages[0].phe_name
          return true
        }
      })
      return name
    },
    deliveryName(id) {
      let name = ''
      this.deliveries.some((value) => {
        if (value.current_history.dhy_id === id) {
          name = value.current_history.languages[0].pivot.dhe_name
          return true
        }
      })
      return name
    },
    confirmAddItem() {
      this.loading = true
      const item = {
        oim_quantity: this.item.product.oim_quantity,
        discount: this.item.product.discount,
        put_id: this.item.product.selected === null ? null : this.item.product.selected.put_id,
        odr_id: parseInt(this.id),
        pon_id: this.item.product.pon_id,
      }
      this.$store.dispatch('order/postItem', { id: this.detail.order_info.odr_id, item: item })
        .then(() => {
          this.initDetail()
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateDeliveryPayment(e, id, form, editable) {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          if (values.dhy_id) {
            this.deliveries.some((value) => {
              if (value.current_history.dhy_id === values.dhy_id) {
                values.dly_id = value.dly_id
                return true
              }
            })
          } else {
            this.payments.some((value) => {
              if (value.current_history.pty_id === values.pty_id) {
                values.pmt_id = value.pmt_id
                return true
              }
            })
          }
          this.loading = true
          this.$store.dispatch('order/putDeliveryPayment', { id: id, data: values })
            .then(() => {
              this.editable[editable] = false
              this.item[editable].changed = true
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    updatePaymentStatus(e, form) {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('order/status', {
            payment_status: form.getFieldValue('payment_status'),
            items: [Number.parseInt(this.id)],
          })
            .then(() => {
              this.editable.payment_status = false
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    onPriceChange(record, value) {
      this.loading = true
      this.$store.dispatch('order/putItem', {
        id: this.id,
        item: record,
        data: {
          price_item_without_discount_with_vat: value,
        },
      })
        .then(() => {
          this.editable.note = null
          this.initDetail()
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    onDiscountChange(record, value) {
      this.loading = true
      this.$store.dispatch('order/putItem', {
        id: this.id,
        item: record,
        data: {
          discount: value,
        },
      })
        .then(() => {
          this.editable.note = null
          this.initDetail()
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    onQuantityChange(record, value) {
      this.loading = true
      this.$store.dispatch('order/putItem', {
        id: this.id,
        item: record,
        data: {
          oim_quantity: value,
        },
      })
        .then(() => {
          this.editable.note = null
          this.initDetail()
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickActionNote(e, item) {
      if (e.key === 0) {
        this.editable.note = item.one_id
      }
    },
    updateBillingAddress(e, id, form, editable) {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('order/putBillingAddress', { orderId: this.id, addressId: id, item: values })
            .then(() => {
              this.editable[editable] = false
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    updateDeliveryAddress(e, id, form, editable) {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('order/putDeliveryAddress', { orderId: this.id, addressId: id, item: values })
            .then(() => {
              this.editable[editable] = false
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    updateContact(e, id, form, editable) {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('order/putContact', { orderId: this.detail.order_info.odr_id, contactId: id, data: values })
            .then(() => {
              this.editable[editable] = false
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    updateCompany(e, id, form, editable) {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('order/putCompany', { orderId: this.detail.order_info.odr_id, companyId: id, data: values })
            .then(() => {
              this.editable[editable] = false
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    updateInfo(e, form, editable) {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('order/status', {
            status: form.getFieldValue('status'),
            items: [Number.parseInt(this.id)],
          })
            .then(() => {
              this.editable[editable] = false
            }).catch(() => {
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    deleteNote(item) {
      this.loading = true
      this.$store.dispatch('order/deleteNote', item)
        .then(() => {
          this.editable.note = null
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    confirmDeleteItem(item) {
      this.loading = true
      this.$store.dispatch('order/deleteItem', { id: this.detail.order_info.odr_id, item: item })
        .then(() => {
          this.initDetail()
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSubmitUpdateNote(e, item) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('order/putNote', { id: item.one_id, item: values })
            .then(() => {
              this.editable.note = null
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    onClickAddNote() {
      this.isAddNote = true
    },
    onSubmitAddNote(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('order/postNote', {
            odr_id: this.id,
            one_title: this.form.getFieldValue('one_title'),
            one_content: this.form.getFieldValue('one_content'),
          })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
              this.isAddNote = false
            })
        }
      })
    },
    onTabChange(key, type) {
      this[type] = key
    },
    toLocaleDate: function (text) {
      return this.$options.filters.formatDate(text)
    },
    loadDeliveries() {
      this.loading = true
      this.$store.dispatch('delivery/getList')
        .then(() => {
          this.deliveries.some((value) => {
            if (value.current_history.dhy_id === this.detail.order_info.dhy_id) {
              this.item.delivery.found = true
              return true
            }
          })
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadPayments() {
      this.loading = true
      this.$store.dispatch('payment/getList')
        .then(() => {
          this.payments.some((value) => {
            if (value.current_history.pty_id === this.detail.order_info.pty_id) {
              this.item.payment.found = true
              return true
            }
          })
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    toTreeData(data) {
      return data.map(x => {
        return {
          key: 'cey' + x.cey_id,
          value: x.cey_id,
          title: '#' + x.cey_id + ' | ' + (x.languages.length > 0 ? x.languages[0].pivot.cle_name : 'není nastaveno'),
          children: this.toTreeData(x.all_children),
        }
      })
    },
    initItem() {
      this.item = {
        delivery: {
          found: false,
          changed: false,
        },
        payment: {
          found: false,
          changed: false,
        },
        categories: {
          loading: false,
        },
        product: {
          selected: null,
          loading: false,
          pon_id: null,
          oim_quantity: 1,
          discount: 0,
          active: null,
        },
      }
    },
    loadData() {
      this.loading = true
      Promise.all([
        this.$store.dispatch('country/getEnabled'),
      ])
        .then(() => {
          this.initDetail()
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  created() {
    this.initDetail()
    if (this.language) {
      this.loadData()
    }
    if (this.categories.length !== 0) {
      this.treeData = this.toTreeData(this.categories)
    }
  },
  watch: {
    selectedCategory(newValue, oldValue) {
      if (newValue !== null && newValue !== oldValue) {
        this.loadProducts()
      }
    },
    categories(newValue, oldValue) {
      if (newValue !== null && newValue.length > 0) {
        this.treeData = this.toTreeData(this.categories)
      }
    },
    'editable.payment'() {
      if (this.payments.length === 0 && this.language) {
        this.loadPayments()
      }
    },
    'editable.delivery'() {
      if (this.deliveries.length === 0 && this.language) {
        this.loadDeliveries()
      }
    },
    language(newId, oldId) {
      if (newId) {
        this.loadData()
      }
    },
    '$route.params.id'(newId, oldId) {
      this.id = newId
      this.initItem()
      this.initDetail()
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
