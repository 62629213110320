<template>
  <div :class="$style['editable-cell']">
    <div v-if="editable" :class="$style['editable-cell-input-wrapper']">
      <a-input-number :step="step" :min="min" :max="max" :value="value" @change="handleChange" @pressEnter="check" /><a-icon
      type="check"
      :class="$style['editable-cell-icon-check']"
      @click="check"
    />
    </div>
    <div v-else class="editable-cell-text-wrapper"
         :class="$style['editable-cell-text-wrapper']">
      {{ text || ' ' }}
      <a-icon type="edit" :class="$style['editable-cell-icon']" @click="edit" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Editable-cell',
  props: {
    text: String,
    inputValue: Number,
    min: {
      type: Number,
      default: 1,
    },
    step: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      value: this.inputValue,
      editable: false,
    }
  },
  methods: {
    handleChange(e) {
      this.value = e
    },
    check() {
      this.editable = false
      this.$emit('change', this.value)
    },
    edit() {
      this.editable = true
    },
  },
}
</script>
<style lang="scss" module>
  .editable-cell {
    position: relative;
  }

  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    line-height: 18px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell:hover .editable-cell-icon {
    display: inline-block;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
</style>
