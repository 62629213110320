<template>
  <div>
    <a-dropdown :placement="'bottomLeft'">
      <a-button>
        <a-icon type="credit-card"></a-icon>
        ComGate
        <a-icon type="down"/>
      </a-button>
      <a-menu slot="overlay">
        <a-menu-item :disabled="loading || !isRefundValid()" :class="{'disabled': loading || !isRefundValid()}" @click="showRefundModal()" key="comgate-1">
          Refundace
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-modal v-model="refundModal.visible" :confirm-loading="loading" @ok="submitRefundModal" title="Refundace">
      <div class="text-black mb-3">
        ID Transakce: {{ getTransId() }}
      </div>
      <a-form :form="form" layout="vertical">
        <a-form-item
          label="Částka [Kč]"
          :validate-status="error('amount') ? 'error' : ''"
          :help="error('amount') || ''"
        >
          <a-input-number placeholder="Částka"
                          :step="0.01" :min="1.00" :precision="2"
                          v-decorator="['amount', {initialValue: 1.00, rules: [{required: true, message: 'Částka musí být vyplněna!'}]}]"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'my-comgate-button',
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  props: {
    orderId: [Number, String],
    payments: Array,
  },
  data() {
    return {
      loading: false,
      refundModal: {
        visible: false,
      },
    }
  },
  methods: {
    isRefundValid: function () {
      let found = false
      this.payments.some(x => {
        if (x.status === 'PAID') {
          found = true
          return true
        }
      })
      return found
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    showRefundModal: function () {
      this.refundModal.visible = true
    },
    getTransId: function () {
      let found = null
      this.payments.some(x => {
        if (x.status === 'PAID') {
          found = x.transaction_id
          return true
        }
      })
      return found
    },
    getData: function () {
      return {
        order_id: this.orderId,
        transaction_id: this.getTransId(),
        amount: this.getAmountToJson(),
      }
    },
    getAmountToJson: function () {
      if (this.form.getFieldValue('amount') === undefined || this.form.getFieldValue('amount') === null) {
        return 0
      }
      return Number.parseFloat(this.form.getFieldValue('amount')) * 100
    },
    submitRefundModal: function (e) {
      e.preventDefault()
      this.loading = true
      this.form.validateFields((err) => {
        if (!err) {
          this.$store.dispatch('comgate/refund', this.getData())
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
